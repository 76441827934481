<template>
  <v-layout row wrap>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Estadisticas" subtitle="Ventas del producto en las fechas ingresadas"> </titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs4>
              <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-btn class="mt-2" color="success" block @click="getStatistics">
                Filtrar
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs4>
      <v-card class="mb-1">
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 text-xs-right>
              <h2 class="display-3" style="font-weight: 300;">
                {{ montoTotal | toPrice }}
              </h2>
              <h3 class="subheading">Monto Total</h3>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs4>
      <v-card class="mb-1">
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 text-xs-right>
              <h2 class="display-3" style="font-weight: 300;" v-html="rows.length"></h2>
              <h3 class="subheading">Pedidos entregados</h3>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs4>
      <v-card class="mb-1">
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 text-xs-right>
              <h2 v-if="rows.length > 0" class="display-3" style="font-weight: 300;">
                {{ (montoTotal / rows.length) | toPrice }}
              </h2>
              <h2 v-else class="display-3" style="font-weight: 300;">
                {{ 0 | toPrice }}
              </h2>
              <h3 class="subheading">Precio promedio</h3>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex>
      <v-card class="mb-1">
        <v-card-text>
          <LineChart :chart-data="datacollection" :options="optionsData" :style="myStyle"></LineChart>
          <v-radio-group v-model="type" class="ma-0" row>
            <v-radio label="Día" value="day" hide-details></v-radio>
            <v-radio label="Mes" value="month" hide-details></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import qs from 'qs'
import { GET_PRODUCT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import LineChart from '../useful/lineChart'

export default {
  name: 'ProductStatistics',
  components: { titleCard, LineChart },
  props: ['product', 'getProduct'],
  data() {
    return {
      loadingBtn: false,
      loading: false,
      active: true,
      stock: true,
      scheduleRetirement: 0,
      detail: {},
      datacollection: null,
      rows: [],
      type: 'day',
      dateStart: this.$moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment().format('YYYY-MM-DD'),
      states: [
        { name: 'Florida', abbr: 'FL', id: 1 },
        { name: 'Georgia', abbr: 'GA', id: 2 },
        { name: 'Nebraska', abbr: 'NE', id: 3 },
        { name: 'California', abbr: 'CA', id: 4 },
        { name: 'New York', abbr: 'NY', id: 5 }
      ],
      optionsData: {
        hover: {
          // Overrides the global setting
          mode: 'index'
        },
        line: {
          backgroundColor: '#000000'
        },
        legend: {
          labels: {
            fontColor: 'grey',
            fontSize: 12
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: 'grey',
                fontSize: 8,
                suggestedMin: 0
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: 'grey',
                fontSize: 12,
                stepSize: 1,
                beginAtZero: true
              }
            }
          ]
        },
        reponsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    montoTotal() {
      return this._.sumBy(this.rows, 'value')
    },
    days() {
      const shipping = this._.groupBy(this._.orderBy(this.rows, [this.typeFilter], ['asc']), this.typeFilter)
      const days = []
      this._.forEach(shipping, value => {
        days.push(value[0][this.typeFilter])
      })
      return days
    },
    daysValue() {
      const shipping = this._.groupBy(this._.orderBy(this.rows, [this.typeFilter], ['asc']), this.typeFilter)
      const daysData = []
      this._.forEach(shipping, value => {
        daysData.push(this._.sumBy(value, 'value'))
      })
      return daysData
    },
    daysTotal() {
      const shipping = this._.groupBy(this._.orderBy(this.rows, [this.typeFilter], ['asc']), this.typeFilter)
      const daysData = []
      this._.forEach(shipping, value => {
        daysData.push(this._.sumBy(value, 'quantity'))
      })
      return daysData
    },
    typeFilter() {
      return this.type === 'day' ? 'date_shipping' : 'date_month'
    },
    myStyle() {
      return {
        'max-height': '250px'
      }
    }
  },
  watch: {
    product() {
      this.initComponent()
    },
    type() {
      this.initComponent()
    }
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async getStatistics() {
      if (this.detail.id !== undefined) {
        try {
          const res = await this.$http.get(
            `${GET_PRODUCT}/${this.detail.id}/statistics?${qs.stringify({
              dateStart: this.dateStart,
              dateEnd: this.dateEnd
            })}`
          )
          this.rows = res.data
          this.setFillDataOne(this.days, this.daysValue)
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    setFillDataOne(labels, data) {
      this.datacollection = {
        labels,
        datasets: [
          {
            label: 'Monto',
            borderColor: '#637bfe',
            backgroundColor: '#637bfe',
            showLine: true,
            spanGaps: true,
            data,
            lineTension: 0,
            scaleStepWidth: 1
          }
        ]
      }
    },
    initComponent() {
      this.detail = this.product
      this.getStatistics()
    }
  }
}
</script>
